<template>
  <!-- hidden PageHeaderWrapper title demo -->
  <div>
    <a-card :body-style="{padding: '24px 32px'}" :bordered="false">
      <a-form-item
        :label="$t('field.pickupStockName')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'pickupStockListData.pickupStockName',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.pickupStockName') },
                           { max: 100, message: this.$t('field.pickupStockName')+'长度小于等于100' }]}
          ]"
          name="pickupStockListData.pickupStockName"
          v-model="pickupStockListData.pickupStockName"
          :placeholder="'请输入'+ this.$t('field.pickupStockName')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.contactName')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'pickupStockListData.contactName',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.contactName') },
                           { max: 100, message: this.$t('field.contactName')+'长度小于等于100' }]}
          ]"
          name="pickupStockListData.contactName"
          v-model="pickupStockListData.contactName"
          :placeholder="'请输入'+ this.$t('field.contactName')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.contactPhone')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'pickupStockListData.contactPhone',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.contactPhone') },
                           { max: 100, message: this.$t('field.contactPhone')+'长度小于等于100' }]}
          ]"
          name="pickupStockListData.contactPhone"
          v-model="pickupStockListData.contactPhone"
          :placeholder="'请输入'+ this.$t('field.contactPhone')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.address1')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'pickupStockListData.address1',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.address1') },
                           { max: 100, message: this.$t('field.address1')+'长度小于等于100' }]}
          ]"
          name="pickupStockListData.address1"
          v-model="pickupStockListData.address1"
          :placeholder="'请输入'+ this.$t('field.address1')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.address2')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'pickupStockListData.address2',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.address2') },
                           { max: 100, message: this.$t('field.address2')+'长度小于等于100' }]}
          ]"
          name="pickupStockListData.address2"
          v-model="pickupStockListData.address2"
          :placeholder="'请输入'+ this.$t('field.address2')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.address3')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'pickupStockListData.address3',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.address3') },
                           { max: 100, message: this.$t('field.address3')+'长度小于等于100' }]}
          ]"
          name="pickupStockListData.address3"
          v-model="pickupStockListData.address3"
          :placeholder="'请输入'+ this.$t('field.address3')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.addressDetail')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'pickupStockListData.addressDetail',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.addressDetail') },
                           { max: 100, message: this.$t('field.addressDetail')+'长度小于等于100' }]}
          ]"
          name="pickupStockListData.addressDetail"
          v-model="pickupStockListData.addressDetail"
          :placeholder="'请输入'+ this.$t('field.addressDetail')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.fullAddress')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'pickupStockListData.fullAddress',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.fullAddress') },
                           { max: 100, message: this.$t('field.fullAddress')+'长度小于等于100' }]}
          ]"
          name="pickupStockListData.fullAddress"
          v-model="pickupStockListData.fullAddress"
          :placeholder="'请输入'+ this.$t('field.fullAddress')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.state')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'pickupStockListData.state',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.state') },
                           { max: 100, message: this.$t('field.state')+'长度小于等于100' }]}
          ]"
          name="pickupStockListData.state"
          v-model="pickupStockListData.state"
          :placeholder="'请输入'+ this.$t('field.state')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.country')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-select mode="default" v-model="pickupStockListData.country">
          <a-select-option
            v-for="(item, index) in codeList.country"
            :key="index"
            :value="item.value">{{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>

    </a-card>
  </div>
</template>

<script>
export default {
  name: 'EditPickupStock',
  props: {
    data: {
    }
  },
  data () {
    return {
        codeList: {
  'country': []
}
    }
  },
  methods: {

  }
}

</script>
<style>

</style>
