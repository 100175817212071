<template>
  <div>
    <div v-if="showData">
      <a-card :bordered="false">
        <a-descriptions title="" bordered>
          <a-descriptions-item label="拼单活动ID">{{ data.activityId }}</a-descriptions-item>
          <a-descriptions-item label="活动编码">{{ data.activityCode }}</a-descriptions-item>
          <!-- <a-descriptions-item label="拼单活动名称">{{ data.activityName }}</a-descriptions-item> -->
          <!-- <a-descriptions-item label="活动描述">{{ data.activityDesc }}</a-descriptions-item> -->
          <!-- <a-descriptions-item label="活动状态">{{ getCode('activityStatus', data.activityStatus) }}</a-descriptions-item>
          <a-descriptions-item label="活动状态说明">{{ data.activityStatusDesc }}</a-descriptions-item> -->
          <a-descriptions-item label="锁定状态">{{ getCode('lockStatus', data.status) }}</a-descriptions-item>
          <a-descriptions-item label="团员数量">{{ formatCount(data.memberCount) }}</a-descriptions-item>
          <a-descriptions-item label="订单总金额">{{ formatTypeMoney(data.totalAmount, data.currentType, data.moneyMultiple) }}</a-descriptions-item>
          <a-descriptions-item label="商品总数量">{{ formatCount(data.totalQuantity) }}</a-descriptions-item>
          <a-descriptions-item label="订单数量">{{ formatCount(data.saleOrderCount) }}</a-descriptions-item>
          <a-descriptions-item label="包裹数量">{{ formatCount(data.packageCount) }}</a-descriptions-item>
          <!-- <a-descriptions-item label="最少会员数">{{ data.memberLimitMin }}</a-descriptions-item> -->
          <!-- <a-descriptions-item label="最多会员数">{{ data.memberLimitMax }}</a-descriptions-item> -->
        </a-descriptions>
        <br>
        <a-descriptions bordered title="团长信息">
          <a-descriptions-item label="会员ID">{{ data.leaderInfo.memberId }}</a-descriptions-item>
          <a-descriptions-item label="会员昵称">{{ data.leaderInfo.memberNickName }}</a-descriptions-item>
          <a-descriptions-item label="会员头像"><img style="max-height:100px" :src="data.leaderInfo.memberAvatar"/>
          </a-descriptions-item>
          <!-- <a-descriptions-item label="会员账号">{{ data.leaderInfo.memberAccount }}</a-descriptions-item> -->
          <a-descriptions-item label="会员电话">{{ data.leaderInfo.memberPhone }}</a-descriptions-item>
        </a-descriptions>
        <br>
        <!-- <a-descriptions bordered title="订单信息">
          <a-descriptions-item label="订单总金额">{{ data.totalAmount }}</a-descriptions-item>
          <a-descriptions-item label="订单总金额">{{ data.totalAmountShow }}</a-descriptions-item>
          <a-descriptions-item label="总数量">{{ formatCount(data.totalQuantity) }}</a-descriptions-item>
          <a-descriptions-item label="订单数量">{{ formatCount(data.saleOrderCount) }}</a-descriptions-item>
          <a-descriptions-item label="运费">{{ data.shippingFee }}</a-descriptions-item>
          <a-descriptions-item label="运费">{{ data.shippingFeeShow }}</a-descriptions-item>
          <a-descriptions-item label="包裹数量">{{ formatCount(data.packageCount) }}</a-descriptions-item>
        </a-descriptions> -->
        <div v-if="data && data.saleOrderList" class="title">订单信息</div>
        <a-table
          v-if="data && data.saleOrderList"
          :columns="saleOrderListColumns"
          :dataSource="data.saleOrderList"
          :bordered="true"
          :row-key="(record, index) => index"
          :pagination="false">
          <span slot="serial" slot-scope="text, record, index">
            {{ index + 1 }}
          </span>
          <div slot="saleOrderStatus" slot-scope="text,record">
            <p>{{ getCode('saleOrderStatus', text) }}</p>
            <span style="font-size:12px">{{ record.orderCancelDesc }}</span>
          </div>
          <div slot="datetime" slot-scope="text">
            {{ formatDateTime(text) }}
          </div>
        </a-table>
        <br>
        <a-descriptions bordered title="">
          <!-- <a-descriptions-item label="创建人编码">{{ data.creatorCode }}</a-descriptions-item> -->
          <a-descriptions-item label="创建人">{{ data.creatorName }}</a-descriptions-item>
          <!-- <a-descriptions-item label="时间标签">{{ data.timeTag }}</a-descriptions-item> -->
          <a-descriptions-item label="创建时间">{{ formatDateTime(data.createTime) }}</a-descriptions-item>
          <a-descriptions-item label="开始时间">{{ formatDateTime(data.startTime) }}</a-descriptions-item>
          <a-descriptions-item label="结束时间">{{ formatDateTime(data.endTime) }}</a-descriptions-item>
        </a-descriptions>
        <br>
        <!-- <a-descriptions title="物流信息" v-if="data.logisticModel" bordered>
          <a-descriptions-item label="说明">{{ data.logisticModel.desc }}</a-descriptions-item>
        </a-descriptions> -->
        <!-- <a-divider style="margin-bottom: 32px"/>
        <div class="title">自提点一览</div>
        <a-table
          v-if="data && data.pickupStockList"
          :columns="pickupStockListColumns"
          :dataSource="data.pickupStockList"
          :pagination="
            data.pickupStockList.length > 5 ? { showSizeChanger: true, showQuickJumper: true, pageSize: 5, total: 50 } : false "
        >
        </a-table> -->
      </a-card>
    </div>
    <a-modal
      :title="$t('自提点一览')"
      :visible="pickupStockListVisible"
      :confirm-loading="pickupStockListConfirmLoading"
      @ok="handlePickupStockListOk"
      @cancel="handlePickupStockListCancel"
    >
      <PickupStock :pickupStockData="pickupStockData" />
    </a-modal>
  </div>
</template>

<script>
import { STable } from '@/components'
import { getActivityDetail } from '@/api/activity/activity'
import PickupStock from '@/views/activity/components/PickupStock'

export default {
  name: 'ActivityDetail',
  components: {
    STable,
    PickupStock
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    options: {
      type: Object
    }
  },
  created () {
  },
  mounted () {
    // getRoleList({t: new Date()})
    // this.loadData({})
  },
  data () {
    return {
      codeList: {},
      queryParam: {},
      data: {},
      pickupStockListColumns: [
        {
          defaultTitle: '自提点ID',
          title: "$t('columnName.pickupStockId')",
          dataIndex: 'pickupStockId',
          key: 'pickupStockId',
          align: 'left'
        },
        {
          defaultTitle: '自提点名称',
          title: "$t('columnName.pickupStockName')",
          dataIndex: 'pickupStockName',
          key: 'pickupStockName',
          align: 'left'
        },
        {
          defaultTitle: '联系人',
          title: "$t('columnName.contactName')",
          dataIndex: 'contactName',
          key: 'contactName',
          align: 'left'
        },
        {
          defaultTitle: '电话',
          title: "$t('columnName.contactPhone')",
          dataIndex: 'contactPhone',
          key: 'contactPhone',
          align: 'left'
        },
        {
          defaultTitle: '地址1',
          title: "$t('columnName.address1')",
          dataIndex: 'address1',
          key: 'address1',
          align: 'left'
        },
        {
          defaultTitle: '地址2',
          title: "$t('columnName.address2')",
          dataIndex: 'address2',
          key: 'address2',
          align: 'left'
        },
        {
          defaultTitle: '地址3',
          title: "$t('columnName.address3')",
          dataIndex: 'address3',
          key: 'address3',
          align: 'left'
        },
        {
          defaultTitle: '详细地址',
          title: "$t('columnName.addressDetail')",
          dataIndex: 'addressDetail',
          key: 'addressDetail',
          align: 'left'
        },
        {
          defaultTitle: '详细地址',
          title: "$t('columnName.fullAddress')",
          dataIndex: 'fullAddress',
          key: 'fullAddress',
          align: 'left'
        },
        {
          defaultTitle: '州/省',
          title: "$t('columnName.state')",
          dataIndex: 'state',
          key: 'state',
          align: 'left'
        },
        {
          defaultTitle: '国家',
          title: "$t('columnName.country')",
          dataIndex: 'country',
          key: 'country',
          align: 'left'
        }
      ],
      saleOrderListColumns: [
         {
          'defaultTitle': '序号',
          'title': this.$t('columnName.remarkNo'),
          'dataIndex': 'remarkNo',
          'key': 'remarkNo',
          'align': 'center',
          width: '2.5%',
          scopedSlots: {
            customRender: 'serial'
          }
        },
        {
          'defaultTitle': '销售单编号',
          'title': this.$t('columnName.saleOrderNo'),
          'dataIndex': 'saleOrderNo',
          'key': 'saleOrderNo',
          'align': 'left'
        },
        {
          defaultTitle: '拼团ID',
          title: this.$t('columnName.activityId'),
          dataIndex: 'activityId',
          key: 'activityId',
          align: 'left'
        },
        // {
        //   defaultTitle: '拼单活动名称', //待与后端确认
        //   title: this.$t('columnName.activityName'),
        //   dataIndex: 'activityName',
        //   key: 'activityName',
        //   align: 'left'
        // },
        {
          defaultTitle: '订单种类',
          title: this.$t('columnName.isSlaveOrder'),
          dataIndex: 'isSlaveOrder',
          key: 'isSlaveOrder',
          align: 'left',
          customRender: (text) => (
           // console.log("isSale="+text),
            this.getCode('isSlaveOrder', text)
            )
        },
        {
          defaultTitle: '销售单状态',
          title: this.$t('columnName.saleOrderStatus'),
          dataIndex: 'saleOrderStatus',
          key: 'saleOrderStatus',
          align: 'center',
          scopedSlots: {
            customRender: 'saleOrderStatus'
          }
        },
        {
          defaultTitle: '销售单提交时间',
          title: this.$t('columnName.saleOrderSubmitTime'),
          dataIndex: 'saleOrderSubmitTime',
          key: 'saleOrderSubmitTime',
          align: 'center',
          scopedSlots: {
            customRender: 'datetime'
          }
        }
      ],
      pickupStockListVisible: false,
      pickupStockListConfirmLoading: false,
      pickupStockData: {},
      showData: false
    }
  },
  methods: {
    // 加载数据方法 必须为 Promise 对象
    loadData (parameter) {
      console.log('options => ', this.options)
      const data = {
        activityCode: this.options.activityCode// this.$route.query.activityCode
      }
      getActivityDetail(Object.assign(parameter, data)).then((res) => {
        console.log('11loadData->getActivityDetail.parameter', parameter, res)
        this.data = res // this.data = res.data
        this.showData = true
        return this.data
      })
    },

    showPickupStockListModal () {
      this.pickupStockListVisible = true
    },
    handlePickupStockListOk (event) {
      this.pickupStockListConfirmLoading = true
      setTimeout(() => {
        this.pickupStockListVisible = false
        this.pickupStockListConfirmLoading = false
      }, 2000)
    },
    handlePickupStockListCancel (event) {
      console.log('Clicked cancel button on pickupStockList')
      this.pickupStockListVisible = false
    }
  },
  filters: {
    statusFilter (status) {
      const statusMap = {
        processing: '进行中',
        success: '完成',
        failed: '失败'
      }
      return statusMap[status]
    }
  },
  computed: {
    title () {
      return this.$route.meta.title
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}
</style>
