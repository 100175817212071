<template>
  <page-header-wrapper>
    <div class="a-card">
      <ActivitySearch :queryParam="queryParam" />
      <s-table
        :bordered="true"
        ref="table"
        :dataSource="data"
        :data="loadData"
        :alert="options.alert"
        :rowSelection="options.rowSelection"
        :pagination="{ showSizeChanger: true, showQuickJumper: true, pageSize: 20, total: 50 }"
        :scroll="{ x: true }"
        :row-key="(record, index) => index"
      >
        <a-table-column :title="$t('columnName.remarkNo')" align="center">
          <template slot-scope="text, record, index">
            {{index+1}}
          </template>
        </a-table-column>
        <a-table-column title="操作" align="center">
          <template slot-scope="record">
           <a @click="handleDetail(record)">{{ $t('button.detail') }}</a>
          </template>
        </a-table-column>
        <!-- 活动编码 -->
        <a-table-column :title="$t('columnName.activityCode')">
          <template slot-scope="record">
            {{record.activityCode}}
          </template>
        </a-table-column>
        <!-- 订单总金额 -->
        <a-table-column :title="$t('columnName.totalAmount')" align="right">
          <template slot-scope="record">
            {{formatTypeMoney(record.totalAmount, record.currentType, record.moneyMultiple)}}
          </template>
        </a-table-column>
        <!-- 总数量 -->
        <a-table-column title="商品总数量" align="right">
          <template slot-scope="record">
            {{record.totalQuantity}}
          </template>
        </a-table-column>
        <!-- 订单数量 -->
        <a-table-column :title="$t('columnName.saleOrderCount')" align="right">
          <template slot-scope="record">
            {{record.saleOrderCount}}
          </template>
        </a-table-column>
        <!-- 包裹数量 -->
        <a-table-column :title="$t('columnName.packageCount')" align="right">
          <template slot-scope="record">
            {{record.packageCount}}
          </template>
        </a-table-column>
        <!-- 活动状态 -->
        <a-table-column :title="$t('columnName.activityStatus')" align="center">
          <template slot-scope="record">
            {{getCode('activityStatus', record.activityStatus)}}
          </template>
        </a-table-column>
        <!-- 开始时间 -->
        <a-table-column :title="$t('columnName.startTime')" align="center">
          <template slot-scope="record">
            {{formatDateTime(record.startTime)}}
          </template>
        </a-table-column>
        <!-- 结束时间 -->
        <a-table-column :title="$t('columnName.endTime')" align="center">
          <template slot-scope="record">
            {{formatDateTime(record.endTime)}}
          </template>
        </a-table-column>
        <!-- 创建人 -->
        <a-table-column :title="$t('columnName.creatorName')" align="left">
          <template slot-scope="text, record">
            {{record.creatorName}}
          </template>
        </a-table-column>
        <!-- 更新人 -->
        <a-table-column :title="$t('columnName.updaterName')" align="left">
          <template slot-scope="text, record">
            {{record.updaterName}}
          </template>
        </a-table-column>
      </s-table>
      <a-drawer
        :closable="false"
        :visible="showDetailDrawer"
        placement="right"
        width="80%"
        @close="onDetailDrawerClose"
      >
        <ActivityDetail ref="activityDetail" :options="detailOptions"></ActivityDetail>
      </a-drawer>
      <CommentEditor ref="commentEditor" @handleOk="handleCommentEditOk" />
      <ApprovalEditor ref="approvalEditor" @handleOk="handleApprovalEditOk" />
    </div> 
  </page-header-wrapper>
</template>

<script>
// 演示如何使用 this.$dialog 封装 modal 组件
import { approvalActivity, disableActivity, enableActivity, searchActivity } from '@/api/activity/activity'
import moment from 'moment'
import { STable } from '@/components'
import ActivitySearch from '@/views/activity/ActivitySearch'
import ActivityDetail from '@/views/activity/ActivityDetail'
import CommentEditor from '@/components/comment/CommentEditor'
import ApprovalEditor from '@/components/approval/ApprovalEditor'

export default {
  name: 'ActivityList',
  components: {
    STable,
    ActivitySearch,
    ActivityDetail,
    CommentEditor,
    ApprovalEditor
  },
  data() {
    return {
      confirmEditLoading: false,
      showDetailDrawer: false,
      detailOptions: {},
      // 查询参数
      queryParam: { activityStatus: [] },
      data: [],
      status: 'all',
      // 加载数据方法 必须为 Promise 对象
      // loadData: parameter => {
      //   console.log('loadData.parameter', parameter)
      //   return getActivity(Object.assign(parameter, this.queryParam))
      //       .then(res => {
      //         return res.result
      //       })
      // },
      selectedRowKeys: [],
      selectedRows: [],
      activityData: {},
      // custom table alert & rowSelection
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          }
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created() {
    this.tableOption()
    // getRoleList({t: new Date()})
  },
  mounted() {},
  methods: {
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      return searchActivity(Object.assign(parameter, this.queryParam)).then(res => {
        // console.log('loadData->getActivityList.parameter', parameter, res)
        return res
      })
    },
    tableOption() {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            }
          },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },

    handleDetail(record) {
      this.detailOptions = {
        activityId: record.activityId,
        activityCode: record.activityCode
      }
      this.showDetailDrawer = true
      this.$nextTick(() => {
        this.$refs.activityDetail.loadData({})
      })
      // this.$router.push({
      //   path:"/activity/ActivityDetail",
      //   query:{
      //     activityId:record.activityId
      //   }
      // })
    },
    handleApproval(record) {
      this.$refs['approvalEditor'].show(record)
    },
    handleApprovalEditOk(params) {
      console.info('===params', params)
      const record = params.record
      record.approvalComment = params.record.comment
      const that = this
      return approvalActivity({
        approvalInfo: record.comment,
        approvalStatus: record.approvalType === 'reject' ? '已拒绝' : '已通过' /** 待审核, 已通过, 已拒绝, 审核中 */,
        activityId: record.activityId
      })
        .then(res => {
          console.log('handleApprovalEditOk', record, res)
          that.$refs.table.refresh(true)
          that.$message.success('审批成功')
          return res
        })
        .catch(() => {
          that.$refs.table.refresh(true)
          that.$message.success('审批失败')
        })
    },
    handleCommentEditOk(params) {
      const record = params.record
      const that = this
      if (params.type === 'disable') {
        return disableActivity(record)
          .then(res => {
            console.log('handleDisable', record, res)
            that.$refs.table.refresh(true)
            that.$message.success('锁定成功')
            return res
          })
          .catch(() => {
            that.$refs.table.refresh(true)
            that.$message.success('解锁失败')
          })
      } else if (params.type === 'enable') {
        return enableActivity(record)
          .then(res => {
            console.log('handleEnable', record, res)
            that.$refs.table.refresh(true)
            that.$message.success('解锁定成功')
            return res
          })
          .catch(() => {
            that.$refs.table.refresh(true)
            that.$message.success('解锁失败')
          })
      }
    },
    handleDisable(record) {
      this.$refs['commentEditor'].show(record, 'disable')
    },
    handleEnable(record) {
      this.$refs['commentEditor'].show(record, 'enable')
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    resetSearchForm() {
      this.queryParam = {
        date: moment(new Date())
      }
    },
    onDetailDrawerClose() {
      this.showDetailDrawer = false
    }
  }
}
</script>
<style lang="less" scoped>
.ant-avatar-lg {
  width: 48px;
  height: 48px;
  line-height: 48px;
}

.list-content-item {
  color: rgba(0, 0, 0, 0.45);
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  margin-left: 40px;

  span {
    line-height: 20px;
  }

  p {
    margin-top: 4px;
    margin-bottom: 0;
    line-height: 22px;
  }
}
</style>
