<template>
  <div class="table-page-search-wrapper">
    <a-form layout="inline">
      <a-row :gutter="24">
        <a-col :md="8" :sm="24" v-if="0">
          <a-form-item
            :label="$t('field.activityName')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-input
              v-decorator="[
                'queryParam.activityName',
                {
                  rules: [
                    { required: true, message: '请输入' + this.$t('field.activityName') },
                    { max: 100, message: this.$t('field.activityName') + '长度小于等于100' }
                  ]
                }
              ]"
              name="queryParam.activityName"
              v-model="queryParam.activityName"
              :placeholder="'请输入' + this.$t('field.activityName')"
            />
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24">
          <a-form-item
            :label="$t('field.activityCode')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-input
              v-decorator="[
                'queryParam.activityCode',
                {
                  rules: [
                    { required: true, message: '请输入' + this.$t('field.activityCode') },
                    { max: 100, message: this.$t('field.activityCode') + '长度小于等于100' }
                  ]
                }
              ]"
              name="queryParam.activityCode"
              v-model="queryParam.activityCode"
              :placeholder="'请输入' + this.$t('field.activityCode')"
            />
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24">
          <a-form-item
            :label="$t('field.activityStatus')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-select
              :allowClear="true"
              mode="multiple"
              v-model="queryParam.activityStatus"
              :placeholder="'请选择' + this.$t('field.activityStatus')"
            >
              <a-select-option v-for="(item, index) in codeList.activityStatus" :key="index" :value="item.value"
                >{{ item.label }}
              </a-select-option>
            </a-select></a-form-item
          >
        </a-col>
        <a-col :md="8" :sm="24">
          <a-form-item
            v-if="showAdvancedSearchCondition"
            :label="$t('field.approvalStatus')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-select
              :allowClear="true"
              v-model="queryParam.approvalStatus"
              :placeholder="'请选择' + this.$t('field.approvalStatus')"
            >
              <a-select-option v-for="(item, index) in codeList.approvalStatus" :key="index" :value="item.value"
                >{{ item.label }}
              </a-select-option>
            </a-select></a-form-item
          >
        </a-col>
        <a-col :md="8" :sm="24">
          <a-form-item
            v-if="showAdvancedSearchCondition"
            :label="$t('field.status')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-select :allowClear="true" v-model="queryParam.status" :placeholder="'请选择' + this.$t('field.status')">
              <a-select-option v-for="(item, index) in codeList.status" :key="index" :value="item.value"
                >{{ item.label }}
              </a-select-option>
            </a-select></a-form-item
          >
        </a-col>
        <!-- <a-col :md="8" :sm="24">
          <a-form-item
            v-if="showAdvancedSearchCondition"
            :label="$t('field.leaderName')"
            :labelCol="{lg: {span: 7}, sm: {span: 7}}"
            :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
            <a-input
              v-decorator="[ 'queryParam.leaderName',
                             {rules: [
                               { required: true, message: '请输入'+this.$t('field.leaderName') },
                               { max: 100, message: this.$t('field.leaderName')+'长度小于等于100' }]}
              ]"
              name="queryParam.leaderName"
              v-model="queryParam.leaderName"
              :placeholder="'请输入'+ this.$t('field.leaderName')"/>
          </a-form-item>
        </a-col> -->
        <!-- <a-col :md="8" :sm="24">
          <a-form-item
            v-if="showAdvancedSearchCondition"
            :label="$t('field.leaderAccount')"
            :labelCol="{lg: {span: 7}, sm: {span: 7}}"
            :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
            <a-input
              v-decorator="[ 'queryParam.leaderAccount',
                             {rules: [
                               { required: true, message: '请输入'+this.$t('field.leaderAccount') },
                               { max: 100, message: this.$t('field.leaderAccount')+'长度小于等于100' }]}
              ]"
              name="queryParam.leaderAccount"
              v-model="queryParam.leaderAccount"
              :placeholder="'请输入'+ this.$t('field.leaderAccount')"/>
          </a-form-item>
        </a-col> -->
        <!-- <a-col :md="8" :sm="24">
          <a-form-item
            v-if="showAdvancedSearchCondition"
            :label="$t('field.createTimeRange')"
            :labelCol="{lg: {span: 7}, sm: {span: 7}}"
            :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
            <a-range-picker
              name="queryParam.createTimeRange"
              style="width: 100%"
              v-modle="queryParam.createTimeRange"
              v-decorator="['queryParam.createTimeRange',{rules: [{ required: true, message: '请选择起止日期' }]}]"/>
          </a-form-item>
        </a-col> -->
      </a-row>
      <a-row>
        <a-col :md="24" :sm="24">
          <span
            class="table-page-search-submitButtons"
            :style="(showAdvancedSearchCondition && { overflow: 'hidden' }) || {}"
          >
            <a-button icon="search" type="primary" @click="$parent.$parent.$refs.table.refresh(true)">{{
              $t('button.search')
            }}</a-button
            ><a-divider type="vertical" />
            <a-button
              style="margin-left: 8px"
              @click="
                () => {
                  $parent.$parent.queryParam = {}
                  $nextTick(() => {
                    $parent.$parent.$refs.table.refresh(true)
                  })
                }
              "
              >{{ $t('button.reset') }}</a-button
            >
            <a @click="toggleAdvancedSearch" style="margin-left: 8px">
              {{ showAdvancedSearchCondition ? $t('button.collapseIn') : $t('button.collapseOut') }}
              <a-icon :type="showAdvancedSearchCondition ? 'up' : 'down'" />
            </a>
          </span>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  name: 'ActivitySearch',
  components: {},
  props: {
    // 查询参数
    // eslint-disable-next-line vue/require-default-prop
    queryParam: {
      type: Object
    }
  },
  data() {
    return {
      showAdvancedSearchCondition: false,
      codeList: {
        activityStatus: [
          { label: '已创建', value: '已创建' },
          { label: '进行中', value: '进行中' },
          { label: '已取消', value: '已取消' },
          { label: '已关闭', value: '已关闭' },
          { label: '已超时', value: '已超时' },
          { label: '已成团', value: '已成团' },
          { label: '已完成', value: '已完成' }
        ],
        approvalStatus: [
          { label: '待审核', value: '待审核' },
          { label: '审核中', value: '审核中' },
          { label: '已通过', value: '已通过' },
          { label: '已拒绝', value: '已拒绝' }
        ],
        status: [
          { label: '正常', value: '1' },
          { label: '已锁定', value: '0' }
        ]
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    toggleAdvancedSearch() {
      this.showAdvancedSearchCondition = !this.showAdvancedSearchCondition
    },
    resetSearchForm() {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
