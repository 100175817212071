var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-card',{attrs:{"body-style":{padding: '24px 32px'},"bordered":false}},[_c('a-form-item',{attrs:{"label":_vm.$t('field.pickupStockName'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'pickupStockListData.pickupStockName',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.pickupStockName') },
                         { max: 100, message: this.$t('field.pickupStockName')+'长度小于等于100' }]}
        ]),expression:"[ 'pickupStockListData.pickupStockName',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.pickupStockName') },\n                         { max: 100, message: this.$t('field.pickupStockName')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"pickupStockListData.pickupStockName","placeholder":'请输入'+ this.$t('field.pickupStockName')},model:{value:(_vm.pickupStockListData.pickupStockName),callback:function ($$v) {_vm.$set(_vm.pickupStockListData, "pickupStockName", $$v)},expression:"pickupStockListData.pickupStockName"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.contactName'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'pickupStockListData.contactName',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.contactName') },
                         { max: 100, message: this.$t('field.contactName')+'长度小于等于100' }]}
        ]),expression:"[ 'pickupStockListData.contactName',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.contactName') },\n                         { max: 100, message: this.$t('field.contactName')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"pickupStockListData.contactName","placeholder":'请输入'+ this.$t('field.contactName')},model:{value:(_vm.pickupStockListData.contactName),callback:function ($$v) {_vm.$set(_vm.pickupStockListData, "contactName", $$v)},expression:"pickupStockListData.contactName"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.contactPhone'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'pickupStockListData.contactPhone',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.contactPhone') },
                         { max: 100, message: this.$t('field.contactPhone')+'长度小于等于100' }]}
        ]),expression:"[ 'pickupStockListData.contactPhone',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.contactPhone') },\n                         { max: 100, message: this.$t('field.contactPhone')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"pickupStockListData.contactPhone","placeholder":'请输入'+ this.$t('field.contactPhone')},model:{value:(_vm.pickupStockListData.contactPhone),callback:function ($$v) {_vm.$set(_vm.pickupStockListData, "contactPhone", $$v)},expression:"pickupStockListData.contactPhone"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.address1'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'pickupStockListData.address1',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.address1') },
                         { max: 100, message: this.$t('field.address1')+'长度小于等于100' }]}
        ]),expression:"[ 'pickupStockListData.address1',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.address1') },\n                         { max: 100, message: this.$t('field.address1')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"pickupStockListData.address1","placeholder":'请输入'+ this.$t('field.address1')},model:{value:(_vm.pickupStockListData.address1),callback:function ($$v) {_vm.$set(_vm.pickupStockListData, "address1", $$v)},expression:"pickupStockListData.address1"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.address2'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'pickupStockListData.address2',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.address2') },
                         { max: 100, message: this.$t('field.address2')+'长度小于等于100' }]}
        ]),expression:"[ 'pickupStockListData.address2',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.address2') },\n                         { max: 100, message: this.$t('field.address2')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"pickupStockListData.address2","placeholder":'请输入'+ this.$t('field.address2')},model:{value:(_vm.pickupStockListData.address2),callback:function ($$v) {_vm.$set(_vm.pickupStockListData, "address2", $$v)},expression:"pickupStockListData.address2"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.address3'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'pickupStockListData.address3',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.address3') },
                         { max: 100, message: this.$t('field.address3')+'长度小于等于100' }]}
        ]),expression:"[ 'pickupStockListData.address3',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.address3') },\n                         { max: 100, message: this.$t('field.address3')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"pickupStockListData.address3","placeholder":'请输入'+ this.$t('field.address3')},model:{value:(_vm.pickupStockListData.address3),callback:function ($$v) {_vm.$set(_vm.pickupStockListData, "address3", $$v)},expression:"pickupStockListData.address3"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.addressDetail'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'pickupStockListData.addressDetail',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.addressDetail') },
                         { max: 100, message: this.$t('field.addressDetail')+'长度小于等于100' }]}
        ]),expression:"[ 'pickupStockListData.addressDetail',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.addressDetail') },\n                         { max: 100, message: this.$t('field.addressDetail')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"pickupStockListData.addressDetail","placeholder":'请输入'+ this.$t('field.addressDetail')},model:{value:(_vm.pickupStockListData.addressDetail),callback:function ($$v) {_vm.$set(_vm.pickupStockListData, "addressDetail", $$v)},expression:"pickupStockListData.addressDetail"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.fullAddress'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'pickupStockListData.fullAddress',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.fullAddress') },
                         { max: 100, message: this.$t('field.fullAddress')+'长度小于等于100' }]}
        ]),expression:"[ 'pickupStockListData.fullAddress',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.fullAddress') },\n                         { max: 100, message: this.$t('field.fullAddress')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"pickupStockListData.fullAddress","placeholder":'请输入'+ this.$t('field.fullAddress')},model:{value:(_vm.pickupStockListData.fullAddress),callback:function ($$v) {_vm.$set(_vm.pickupStockListData, "fullAddress", $$v)},expression:"pickupStockListData.fullAddress"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.state'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'pickupStockListData.state',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.state') },
                         { max: 100, message: this.$t('field.state')+'长度小于等于100' }]}
        ]),expression:"[ 'pickupStockListData.state',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.state') },\n                         { max: 100, message: this.$t('field.state')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"pickupStockListData.state","placeholder":'请输入'+ this.$t('field.state')},model:{value:(_vm.pickupStockListData.state),callback:function ($$v) {_vm.$set(_vm.pickupStockListData, "state", $$v)},expression:"pickupStockListData.state"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.country'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-select',{attrs:{"mode":"default"},model:{value:(_vm.pickupStockListData.country),callback:function ($$v) {_vm.$set(_vm.pickupStockListData, "country", $$v)},expression:"pickupStockListData.country"}},_vm._l((_vm.codeList.country),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v(_vm._s(item.label)+" ")])}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }