import request from '@/utils/request'
import qs from 'qs'

const baseUrl = '/api'

export function getActivityDetail (parameter) {
  return request({
    url: baseUrl + '/activity/activity/get', // getDetail
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function saveActivityPickupStockData (parameter) {
  let url = baseUrl + '/activity/activity/save'
  if (!parameter.activityId) {
    url = baseUrl + '/activity/activity/create'
  }
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function saveActivityData (parameter) {
  let url = baseUrl + '/activity/activity/save'
  if (!parameter.activityId) {
    url = baseUrl + '/activity/activity/create'
  }
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function approvalActivity (parameter) {
  // const url = baseUrl + '/activity/activity/approval'
  return request({
    url: baseUrl + '/activity/activity/approval',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function searchActivity (parameter) {
  return request({
    url: baseUrl + '/activity/activity/search?' + qs.stringify(parameter, { indices: false }),
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data.list,
      pageNo: res.data.pageInfo.pageNo ? res.data.pageInfo.pageNo : 1,
      totalCount: res.data.pageInfo.totalCount
    }
  })
}

export function listActivity (parameter) {
  return request({
    url: baseUrl + '/activity/activity/list',
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data.list
    }
  })
}

export function getActivityData (parameter) {
  return request({
    url: baseUrl + '/activity/activity/get',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function enableActivity (parameter) {
  return request({
    url: baseUrl + '/activity/activity/enable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function disableActivity (parameter) {
  return request({
    url: baseUrl + '/activity/activity/disable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function closeActivity (parameter) {
  const url = baseUrl + '/activity/activity/close'
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function deleteActivity (parameter) {
  const url = baseUrl + '/activity/activity/delete'
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}
